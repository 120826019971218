import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import ApproveRequest from "./Approve";

const CustomerTable = ({ data, getData }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const [isShowApprove, setIsShowApprove] = useState(false);
    const [idx, setIdx] = useState(null);

    const details = (id) => {
        navigate(`/customer/${id}`);
    };

    const approve = (benefit) => {
        setIdx(benefit);
        setIsShowApprove(true);
    };

    return (
        <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
            <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                    <tr>
                        <th scope="col" className="px-6 py-3">
                            {t("Name")}
                        </th>
                        <th scope="col" className="px-6 py-3">
                            {t("Company")}
                        </th>
                        <th scope="col" className="px-6 py-3">
                            {t("Product name")}
                        </th>
                        <th scope="col" className="px-6 py-3">
                            {t("Policy number")}
                        </th>
                        <th scope="col" className="px-6 py-3">
                            {t("PINFL")}
                        </th>
                        <th scope="col" className="px-6 py-3">
                            {t("Status")}
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {data.map((benefit) => (
                        <tr key={benefit.id} className="border-b dark:bg-gray-800 dark:border-gray-700" onDoubleClick={() => details(benefit.id)}>
                            <td className="px-6 py-4">{benefit.customer_first_name + " " + benefit.customer_last_name}</td>
                            <td className="px-6 py-4">{benefit.company_name}</td>
                            <td className="px-6 py-4">{benefit.product_name + " - " + benefit.product_type_name}</td>
                            <td className="px-6 py-4">{benefit.policy_number}</td>
                            <td className="px-6 py-4">{benefit.PINFL}</td>
                            <td className="pl-6 pr-10 w-[440px]">
                                <div className="flex items-center justify-between max-w-[400px]">
                                    <span>{t(benefit.status_text)}</span>
                                    <div className="flex items-center gap-4 w-fit">
                                        <button
                                            type="button"
                                            style={{ visibility: benefit.status === 1 ? "visible" : "hidden" }}
                                            onClick={() => approve(benefit)}
                                            className="text-green-700 hover:text-white border border-green-700 hover:bg-green-700 focus:outline-none font-medium rounded-lg text-sm px-3 py-1.5 text-center dark:border-green-500 dark:text-green-500 dark:hover:text-white dark:hover:bg-green-500 dark:focus:ring-green-800 transition-all"
                                        >
                                            {t("approve")}
                                        </button>
                                        <button
                                            onClick={() => details(benefit.id)}
                                            type="button"
                                            className="text-blue-700 hover:text-white border border-blue-700 hover:bg-blue-700 focus:outline-none font-medium rounded-lg text-sm px-3 py-1.5 text-center dark:border-blue-500 dark:text-blue-500 dark:hover:text-white dark:hover:bg-blue-500 dark:focus:ring-blue-800 transition-all"
                                        >
                                            {t("details")}
                                        </button>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <ApproveRequest product={idx} isModalOpen={isShowApprove} setIsModalOpen={setIsShowApprove} getData={getData} />
        </div>
    );
};

export default CustomerTable;
