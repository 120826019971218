import React from "react";
import LoginIndex from "../components/Login/Index";

const Login = () => {
    return (
        <div>
            <LoginIndex />
        </div>
    );
};

export default Login;
