import React from "react";
import ContactIndex from "../components/Contact/Index";

const Contact = () => {
    return (
        <div>
            <ContactIndex />
        </div>
    );
};

export default Contact;
