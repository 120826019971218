import regex from "tts-hrp-val-lib";
import * as Yup from "yup";

const user = { first_name: undefined, last_name: undefined, email: undefined, username: undefined, usernameStatus: true };
const changePassword = { password: undefined, new_password: undefined, confirm_password: undefined };
const userUpdate = { first_name: undefined, last_name: undefined };

const validation = (t) => {
    return Yup.object({
        id: Yup.number().optional(),
        first_name: Yup.string().matches(regex.first_name, t("Invalid value")).required(t("Field is required")),
        last_name: Yup.string().matches(regex.last_name, t("Invalid value")).optional().nullable(),
        email: Yup.string().matches(regex.email, t("Invalid value")).required(t("Field is required")),
        username: Yup.string()
            .required(t("Field is required"))
            .matches(regex.username, t("Invalid value"))
            .when("usernameStatus", (status, field) =>
                field.test("busy", t("username is busy"), () => status[0] !== false).test("wait", t("waiting..."), () => status[0] !== null)
            ),
    });
};

const validationUpdate = (t) => {
    return Yup.object({
        id: Yup.number().optional(),
        first_name: Yup.string().matches(regex.first_name, t("Invalid value")).required(t("Field is required")),
        last_name: Yup.string().matches(regex.last_name, t("Invalid value")).optional(),
    });
};

export const changePasswordValidation = (t) => {
    const text = "The password must contain at least one uppercase letter, at least one lowercase letter, at least one number and at least 8 characters";
    return Yup.object({
        password: Yup.string().required(t("Field is required")),
        new_password: Yup.string().matches(regex.password, t(text)).required(t("Field is required")),
        confirm_password: Yup.string()
            .oneOf([Yup.ref("new_password"), null], t("Passwords must match"))
            .required(t("Passwords must match")),
    });
};

export const userInitialValue = user;
export const userValidation = validation;
export const userUpdateInitialValue = userUpdate;
export const userUpdateValidation = validationUpdate;
export const changePasswordInitialValue = changePassword;
