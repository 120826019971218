import * as Yup from "yup";

const login = { username: undefined, password: undefined };

const validation = (t) => {
    return Yup.object({ username: Yup.string().required(t("Field is required")), password: Yup.string().required(t("Field is required")) });
};

export const loginInitialValue = login;
export const loginValidation = validation;
