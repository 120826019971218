import React from "react";
import styles from "./login.module.css";
import { Field, Form, Formik } from "formik";
import { Button } from "antd";
import { LockOutlined, UserOutlined } from "@ant-design/icons";
import { AntInput, AntPassword } from "../../hooks/createAntDFields";
import { loginInitialValue, loginValidation } from "../../constants/login";
import axios from "axios";
import REACT_APP from "../../environment";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { endWait, startWait } from "../../store/waiting";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

const Login = () => {
    const { t } = useTranslation();
    const validate = loginValidation(t);
    const [passwordVisible, setPasswordVisible] = React.useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const onChange = (e) => {
        console.log(`checked = ${e.target.checked}`);
    };

    const submit = async (data, setSubmitting) => {
        dispatch(startWait());
        await axios
            .post(`${REACT_APP.API_URL}/api/hrp/provider/v1/auth/login`, data, { headers: { Authorization: sessionStorage.getItem("token") } })
            .then((res) => {
                sessionStorage.setItem("token", res.data.data.token);
                sessionStorage.setItem("isSupervisor", res.data.data.isSupervisor);
                sessionStorage.setItem("username", data.username);
                sessionStorage.setItem("provider", res.data.data.provider);
                navigate("/");
            })
            .catch((err) => {
                console.log(err);
                toast.error(t((err.response?.data?.errors && err.response.data.errors[0]?.message) || err.message));
            });
        dispatch(endWait());
        setSubmitting(false);
    };

    return (
        <div className={styles.container}>
            <Formik initialValues={loginInitialValue} validationSchema={validate} onSubmit={submit}>
                {({ isSubmitting, submitCount, resetForm }) => (
                    <Form autoComplete="off">
                        <div className={styles.loginBox}>
                            <div className={styles.h}>
                                <h1>{t("Login")}</h1>
                            </div>
                            <Field
                                component={AntInput}
                                submitCount={submitCount}
                                size="large"
                                name="username"
                                placeholder={t("username")}
                                prefix={<UserOutlined color="red" />}
                                className={styles.input}
                            />
                            <Field
                                component={AntPassword}
                                submitCount={submitCount}
                                size="large"
                                name="password"
                                placeholder={t("password")}
                                className={styles.input}
                                visibilityToggle={{ visible: passwordVisible, onVisibleChange: setPasswordVisible }}
                                prefix={<LockOutlined />}
                            />
                            <div className={styles.passwordLine}>{/* <Checkbox onChange={onChange}>remember me</Checkbox> */}</div>
                            <Button type="primary" className={styles.loginButton} size="large" htmlType="submit">
                                {t("Login")}
                            </Button>
                            <div className={styles.forgotPassword}>
                                <div>
                                    &#169;&nbsp;Transoxania&nbsp;{t("LLC")}.&nbsp;{new Date().getFullYear()}
                                </div>
                                {/* <a href="#">forgot password</a> */}
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    );
};

export default Login;
