import React from "react";
import { useTranslation } from "react-i18next";

const CustomerInsurance = ({ data }) => {
    const { t } = useTranslation();

    return (
        <div>
            <div className="app-box-shadow px-10 pt-5 pb-5 gap-x-10 gap-y-2 grid grid-cols-[auto_1fr] mb-5">
                <div className="font-semibold text-gray-700 col-[1_/_3]">
                    <h5 className="text-lg font-semibold leading-none tracking-tight text-gray-900 md:text-lg">{t("Subscription")}:</h5>
                </div>
                <hr className="col-[1_/_3]" />
                <div className="font-semibold text-gray-700">{t("Policy number")}</div>
                <div className="uppercase">{data.policy_number}</div>
                <hr className="col-[1_/_3]" />
                <div className="font-semibold text-gray-700">{t("Status")}</div>
                <div>{t(data.status_text)}</div>
                <hr className="col-[1_/_3]" />
                <div className="font-semibold text-gray-700">{t("Enrollment date")}</div>
                <div className="capitalize">{data.enrollment_date?.slice(0, 10)}</div>
                <hr className="col-[1_/_3]" />
                <div className="font-semibold text-gray-700">{t("Expirition date")}</div>
                <div>{data.expirition_date?.slice(0, 10)}</div>
            </div>

            <div className="app-box-shadow px-10 pt-5 pb-5 gap-x-10 gap-y-2 grid grid-cols-[auto_1fr] mb-5">
                <div className="font-semibold text-gray-700 col-[1_/_3]">
                    <h5 className="text-lg font-semibold leading-none tracking-tight text-gray-900 md:text-lg">{t("Product")}:</h5>
                </div>
                <hr className="col-[1_/_3]" />
                <div className="font-semibold text-gray-700">{data.product_name}</div>
                <div>{data.product_description}</div>
                <hr className="col-[1_/_3]" />
                <div className="font-semibold text-gray-700">{data.product_type_name}</div>
                <div>{data.product_type_description}</div>
                <hr className="col-[1_/_3]" />
                <div className="font-semibold text-gray-700">{t("Insurance amount")}</div>
                <div className="capitalize">{data.insurance_amount}</div>
                <hr className="col-[1_/_3]" />
                <div className="font-semibold text-gray-700">{t("Deductable amount")}</div>
                <div>{data.deductable_amount}</div>
            </div>
        </div>
    );
};

export default CustomerInsurance;
