import React, { useEffect, useState } from "react";
import SpinnerTTS from "../../../assets/spinner/Spinner";
import REACT_APP from "../../../environment";
import axios from "axios";
import { Tabs } from "antd";
import { Alert } from "antd";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import CustomerInfo from "./Customer";
import CustomerInsurance from "./Insurance";

const CustomerDetails = () => {
    const { t } = useTranslation();
    const params = useParams();
    const [data, setData] = useState({});
    const [loading, setLoading] = useState(true);
    const [isError, setIsError] = useState(false);

    const getData = async () => {
        setLoading(true);
        setIsError(false);
        await axios
            .get(`${REACT_APP.API_URL}/api/hrp/provider/v1/customer/${params.id}`, { headers: { Authorization: sessionStorage.getItem("token") } })
            .then((res) => setData(res.data.data.benefit))
            .catch((err) => {
                setIsError(true);
                console.log(err);
                toast.error(t((err.response?.data?.errors && err.response.data.errors[0]?.message) || err.message));
            });
        setLoading(false);
    };

    useEffect(() => {
        getData();
    }, []);

    const onChange = (key) => {
        console.log(key);
    };

    const items = [
        { key: "insurance", label: t("Insurance"), children: <CustomerInsurance data={data} /> },
        { key: "customer", label: t("Customer"), children: <CustomerInfo data={data} /> },
        { key: "bills", label: t("Bills"), children: "Bills", disabled: true },
    ];

    return loading ? (
        <SpinnerTTS />
    ) : isError ? (
        <Alert message={t("An error occurred")} type="error" className="max-w-5xl mx-auto my-10" />
    ) : (
        <div className="container mx-auto py-6">
            {/* <Tabs defaultActiveKey="insurance" items={items} onChange={onChange} /> */}
            <CustomerInfo data={data} />
            <CustomerInsurance data={data} />
        </div>
    );
};

export default CustomerDetails;
