import React, { useEffect, useState } from "react";
import axios from "axios";
import REACT_APP from "../../environment";
import { toast } from "react-toastify";
import { Modal } from "antd";
import { Button } from "antd";
import { useDispatch } from "react-redux";
import { endWait, startWait } from "../../store/waiting";
import { useTranslation } from "react-i18next";
import { policyApproveInitialValue } from "../../constants/policy";
import { policyApproveValidation } from "../../constants/policy";
import { Field, Form, Formik } from "formik";
import { AntInput } from "../../hooks/createAntDFields";

const ApproveRequest = ({ product: benefit, isModalOpen, setIsModalOpen, getData }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const validate = policyApproveValidation(t);
    const [initialValue, setInitalValue] = useState({ ...policyApproveInitialValue });
    const cancel = (resetForm) => {
        if (typeof resetForm === "function") resetForm();
        setIsModalOpen(false);
    };

    const approve = async (data, { setSubmitting, resetForm }) => {
        dispatch(startWait());
        await axios
            .patch(`${REACT_APP.API_URL}/api/hrp/provider/v1/customer/status/approve`, data, { headers: { Authorization: sessionStorage.getItem("token") } })
            .then(() => {
                resetForm();
                toast.success(t("Approved!"));
                getData();
                setIsModalOpen(false);
            })
            .catch((err) => {
                console.log(err);
                toast.error(t((err.response?.data?.errors && err.response.data.errors[0]?.message) || err.message));
            });
        setSubmitting(false);
        dispatch(endWait());
    };

    useEffect(() => {
        setInitalValue({ id: benefit?.id || undefined, policy_number: undefined });
    }, [benefit]);

    return (
        <Formik initialValues={initialValue} enableReinitialize validationSchema={validate} onSubmit={approve}>
            {({ isSubmitting, setSubmitting, submitCount, errors, values, resetForm }) => (
                <Modal title={t("Approve")} open={isModalOpen} footer={[]} onCancel={() => cancel(resetForm)}>
                    <div className="pt-4">
                        <Form autoComplete="off">
                            <div className="mb-2">
                                {t("approve_text_1")}
                                {`${benefit?.customer_first_name} ${benefit?.customer_last_name}`}
                                {t("approve_text_2")}
                                {benefit?.product_name}
                                {t("approve_text_3")}
                            </div>
                            <div>
                                <label htmlFor="name">{t("Policy number")}</label>
                                <Field component={AntInput} submitCount={submitCount} size="large" name="policy_number" />
                            </div>

                            <div className="ant-modal-footer">
                                <Button onClick={() => cancel(resetForm)}>{t("Cancel")}</Button>
                                <Button htmlType="submit" type="primary" disabled={isSubmitting || benefit?.status !== 1}>
                                    {t("Approve")}
                                </Button>
                            </div>
                        </Form>
                    </div>
                </Modal>
            )}
        </Formik>
    );
};
export default ApproveRequest;
