import regex from "tts-hrp-val-lib";
import * as Yup from "yup";

const contact = { name: undefined, phone: undefined, email: undefined, position: undefined };

const validation = (t) => {
    return Yup.object({
        id: Yup.number().optional(),
        name: Yup.string().matches(regex.name, t("Invalid value")).required(t("Field is required")),
        email: Yup.string().matches(regex.email, t("Invalid value")),
        phone: Yup.string()
            .matches(regex.phone, t("Invalid value"))
            .optional()
            .nullable()
            .when("email", (email, field) => (!(email && email[0]) ? field.required(t("Email or phone required")) : field)),
        position: Yup.string().matches(regex.work_position, t("Invalid value")).optional().nullable(),
    });
};

export const contactInitialValue = contact;
export const contactValidation = validation;
