import React from "react";
import { Modal } from "antd";
import axios from "axios";
import { toast } from "react-toastify";
import REACT_APP from "../../../environment";
import { Button } from "antd";
import { useDispatch } from "react-redux";
import { endWait, startWait } from "../../../store/waiting";
import { useTranslation } from "react-i18next";

const DeleteProductType = ({ productType, isModalOpen, setIsModalOpen, getData }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const cancel = () => setIsModalOpen(false);

    const submit = async () => {
        dispatch(startWait());
        const data = { id: productType.id };
        await axios
            .delete(`${REACT_APP.API_URL}/api/hrp/provider/v1/product/type/status/delete`, {
                headers: { Authorization: sessionStorage.getItem("token") },
                data,
            })
            .then(() => {
                getData();
                toast.success(t("Deleted!"));
                setIsModalOpen(false);
            })
            .catch((err) => {
                console.log(err);
                toast.error(t((err.response?.data?.errors && err.response.data.errors[0]?.message) || err.message));
            });
        dispatch(endWait());
    };

    return (
        <>
            <Modal title={t("Delete product type")} open={isModalOpen} footer={[]} onCancel={cancel}>
                <div className="pt-4">
                    {t("Are you sure you want to delete this product type? Once deleted, it cannot be recovered in any way")}
                    <div className="ant-modal-footer">
                        <Button onClick={cancel}>{t("Cancel")}</Button>
                        <Button type="primary" onClick={submit} danger>
                            {t("Delete")}
                        </Button>
                    </div>
                </div>
            </Modal>
        </>
    );
};
export default DeleteProductType;
