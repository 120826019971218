import React, { useEffect, useState } from "react";
import REACT_APP from "../../environment";
import axios from "axios";
import { Alert } from "antd";
import { Button } from "antd";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";

const CheckNewRequest = ({ getData, data }) => {
    const { t } = useTranslation();
    const [check, setCheck] = useState(false);
    const [check2, setCheck2] = useState(false);

    const checkNewRequest = async () => {
        await axios
            .get(`${REACT_APP.API_URL}/api/hrp/provider/v1/customer/request`, { headers: { Authorization: sessionStorage.getItem("token") } })
            .then((res) => setCheck(res.data.data.check))
            .catch((err) => console.log(err));
    };

    useEffect(() => {
        checkNewRequest();
    }, []);

    // const navigate = useNavigate();
    // const openNewRequest = () => {
    //     navigate(`/policy?status=1&page=1&rows=20`);
    //     setTimeout(() => {
    //         getData();
    //     });
    // };

    const openNewRequest = () => {
        window.location = `/policy?status=1&page=1&rows=20`;
    };

    const [searchParams] = useSearchParams();
    useEffect(() => {
        const status = searchParams.get("status");
        setCheck2(!(status === 1 || status?.includes("1")));
    }, [data]);

    return (
        <>
            {check && check2 && (
                <Alert
                    message={
                        <div className="flex justify-between items-center">
                            <span>{t("There are new requests")}</span>
                            <Button type="primary" onClick={openNewRequest}>
                                {t("Open")}
                            </Button>
                        </div>
                    }
                    type="info"
                    className="mx-5 mb-5"
                />
            )}
        </>
    );
};
export default CheckNewRequest;
