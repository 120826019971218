import React, { useState } from "react";
import Cookies from "js-cookie";
import { useTranslation } from "react-i18next";
import "./style.css";

const Footer = () => {
    // to-do: 31-dekabrdan 1-yanvarga o'tishda yil uchun detector qo'shish
    const { i18n, t } = useTranslation();
    const [lang, setLang] = useState(Cookies.get("i18next"));

    i18n.on("languageChanged", () => {
        setLang(Cookies.get("i18next"));
    });

    return (
        <div className="footer">
            <div>
                &#169;&nbsp;Transoxania&nbsp;{t("LLC")}.&nbsp;{new Date().getFullYear()}
            </div>
        </div>
    );
};

export default Footer;
