import { DesktopOutlined, LogoutOutlined, MenuUnfoldOutlined, UserOutlined } from "@ant-design/icons";
import { BarChartOutlined, ProductOutlined, SolutionOutlined } from "@ant-design/icons";
import { HomeOutlined, MenuFoldOutlined, TeamOutlined } from "@ant-design/icons";
import { Button, Dropdown, Layout, Menu } from "antd";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import avatarImg from "../../assets/avatar.jpg";
import styles from "./header.module.css";
import Home from "../../pages/Home";
import Footer from "../Footer/Index";
import Product from "../../pages/Product";
import { logout } from "../../services/logout";
import User from "../../pages/User";
import { useTranslation } from "react-i18next";
import Cookies from "js-cookie";
import en from "../../icons/en.png";
import uz from "../../icons/uz.png";
import ru from "../../icons/ru.png";
import { Select } from "antd";
import Contact from "../../pages/Contact";
import Profile from "../../pages/Profile";
import Policy from "../../pages/Policy";
import CustomerDetails from "../Policy/Deatils/Details";

const { Header, Sider, Content } = Layout;
function getItem(label, key, icon, children, type) {
    return { key, icon, children, label, type };
}

const App = () => {
    const { t, i18n } = useTranslation();
    const [lang, setlang] = useState(Cookies.get("i18next"));

    const langItems = [
        {
            value: "en",
            label: (
                <div className="flex items-center flex-row gap-2 pe-1 text-[15px] font-semibold m-0">
                    <img className="w-[23px]" src={en} alt="en" /> <span>en</span>
                </div>
            ),
        },
        {
            value: "ru",
            label: (
                <div className="flex items-center flex-row gap-2 pe-1 text-[15px] font-semibold m-0">
                    <img className="w-[23px]" src={ru} alt="ru" /> <span>ru</span>
                </div>
            ),
        },
        {
            value: "uz",
            label: (
                <div className="flex items-center flex-row gap-2 pe-1 text-[15px] font-semibold m-0">
                    <img className="w-[23px]" src={uz} alt="uz" /> <span>uz</span>
                </div>
            ),
        },
    ];
    const onLanguage = (lng) => {
        i18n.changeLanguage(lng);
        setlang(lng);
    };

    const [items, setItems] = useState([
        getItem(t("Home"), "/home", <HomeOutlined />),
        getItem(t("Policies"), "/policy?status=4&page=1&rows=20", <BarChartOutlined />),
        getItem(t("Products"), "/product", <ProductOutlined />),
        getItem(t("Users"), "/user", <TeamOutlined />),
        getItem(t("Contacts"), "/contact", <SolutionOutlined />),
        getItem(t("Transoxania"), "/tts", <DesktopOutlined />),
    ]);

    i18n.on("languageChanged", () => {
        setItems([
            getItem(t("Home"), "/home", <HomeOutlined />),
            getItem(t("Policies"), "/policy?status=4&page=1&rows=20", <BarChartOutlined />),
            getItem(t("Products"), "/product", <ProductOutlined />),
            getItem(t("Users"), "/user", <TeamOutlined />),
            getItem(t("Contacts"), "/contact", <SolutionOutlined />),
            getItem(t("Transoxania"), "/tts", <DesktopOutlined />),
        ]);
    });

    const profile = () => {
        navigate("/profile");
    };

    const avatarItems = [
        getItem(<div onClick={() => profile()}>{t("Profile")}</div>, "/profile", <UserOutlined />),
        getItem(<div onClick={() => logout(navigate)}>{t("Logout")}</div>, "/logout", <LogoutOutlined />),
    ];

    const [collapsed, setCollapsed] = useState(false);
    const [Icon, setIcon] = useState(<MenuFoldOutlined />);
    const [img, setImg] = useState(null);
    const navigate = useNavigate();

    const collapseHandler = () => {
        setCollapsed(!collapsed);
        setIcon(collapsed ? <MenuFoldOutlined /> : <MenuUnfoldOutlined />);
    };

    useEffect(() => {}, []);

    return (
        <>
            <Layout className={styles.appLayout}>
                <Sider trigger={null} collapsible collapsed={collapsed ? 1 : 0}>
                    <Menu onClick={(item) => navigate(item.key)} mode="inline" theme="dark" collapsed={collapsed ? 1 : 0} items={items} />
                </Sider>

                <Layout>
                    <Header theme="dark" className={styles.appHeader}>
                        <Button type="text" icon={Icon} onClick={collapseHandler} className={styles.iconCollapse} />
                        <div className="flex items-center flex-row gap-4">
                            <Select defaultValue={lang} style={{ width: 90 }} onChange={onLanguage} options={langItems} />
                            <Dropdown menu={{ items: avatarItems }} trigger={["click"]}>
                                <img src={img ? img : avatarImg} alt="avatar" className={styles.avatar} />
                            </Dropdown>
                        </div>
                    </Header>
                    <Content className={styles.content}>
                        <div className={styles.page}>
                            <Routes>
                                <Route path="/" exact element={<Home />} />
                                <Route path="/home" element={<Home />} />
                                <Route path="/policy" element={<Policy />} />
                                <Route path="/customer/:id" element={<CustomerDetails />} />
                                <Route path="/product" element={<Product />} />
                                <Route path="/profile" element={<Profile />} />
                                <Route path="/user" element={<User />} />
                                <Route path="/contact" element={<Contact />} />
                                <Route path="/tts" element={<Home />} />
                            </Routes>
                        </div>
                        <Footer />
                    </Content>
                </Layout>
            </Layout>
        </>
    );
};
export default App;
