import React from "react";
import image from "../../../assets/avatar-2.png";
import { useTranslation } from "react-i18next";

const CustomerInfo = ({ data }) => {
    const { t } = useTranslation();

    return (
        <div>
            <div className="xl:grid grid-cols-[auto_1fr_1fr] gap-10 mb-5">
                <div className="app-box-shadow p-10 text-center flex flex-col items-center">
                    <img src={image} className="w-[160px]" alt="avatar" />
                    <h1 className="mt-2 text-lg font-semibold leading-none tracking-tight text-gray-900 md:text-md">
                        {data.customer_first_name} {data.customer_last_name}
                    </h1>
                </div>
                <div className="app-box-shadow px-10 pt-10 pb-5 gap-x-10 grid grid-cols-[auto_1fr]">
                    <div className="font-semibold text-gray-700">{t("First name")}</div>
                    <div className="capitalize">{data.customer_first_name}</div>
                    <hr className="col-[1_/_3]" />
                    <div className="font-semibold text-gray-700">{t("Last name")}</div>
                    <div className="capitalize">{data.customer_last_name}</div>
                    {data.customer_middle_name && (
                        <React.Fragment>
                            <hr className="col-[1_/_3]" />
                            <div className="font-semibold text-gray-700">{t("Middle name")}</div>
                            <div className="capitalize">{data.customer_middle_name}</div>
                        </React.Fragment>
                    )}
                    <hr className="col-[1_/_3]" />
                    <div className="font-semibold text-gray-700">{t("Birth date")}</div>
                    <div className="lowercase">{data.customer_birth_date?.slice(0, 10)}</div>
                    <hr className="col-[1_/_3]" />
                    <div className="font-semibold text-gray-700">{t("Company")}</div>
                    <div className="capitalize">{data.company_name}</div>
                    {data.company_branch === true && (
                        <React.Fragment>
                            <hr className="col-[1_/_3]" />
                            <div className="font-semibold text-gray-700">{t("Branch")}</div>
                            <div className="capitalize">{data.company_branch_name}</div>
                        </React.Fragment>
                    )}
                </div>
                <div className="app-box-shadow px-10 pt-10 pb-5 gap-x-10 grid grid-cols-[auto_1fr]">
                    <div className="font-semibold text-gray-700">{t("Email")}</div>
                    <div>{data.customer_email}</div>
                    <hr className="col-[1_/_3]" />
                    <div className="font-semibold text-gray-700">{t("Cell phone")}</div>
                    <div>{data.customer_cell_phone}</div>
                    <hr className="col-[1_/_3]" />
                    <div className="font-semibold text-gray-700">{t("Home phone")}</div>
                    <div>{data.customer_home_phone}</div>
                    <hr className="col-[1_/_3]" />
                    <div className="font-semibold text-gray-700">{t("Citizenship")}</div>
                    <div className="capitalize">{data.customer_citizenship}</div>
                    <hr className="col-[1_/_3]" />
                    <div className="font-semibold text-gray-700">{t("Nationality")}</div>
                    <div>{data.customer_nationality}</div>
                    <hr className="col-[1_/_3]" />
                    <div className="font-semibold text-gray-700">{t("PINFL")}</div>
                    <div className="capitalize">{data.PINFL}</div>
                </div>
            </div>
        </div>
    );
};

export default CustomerInfo;
