import regex from "tts-hrp-val-lib";
import * as Yup from "yup";

const product = { name: undefined, description: undefined };
const productType = { name: undefined, description: undefined, insurance_amount: undefined, deductable_amount: undefined };

const validation = (t) => {
    return Yup.object({
        id: Yup.number().optional(),
        name: Yup.string().matches(regex.name, t("Invalid value")).required(t("Field is required")),
        description: Yup.string().matches(regex.description, t("Invalid value")).required(t("Field is required")),
    });
};

const typeValidation = (t) => {
    return Yup.object({
        id: Yup.number().optional(),
        name: Yup.string().matches(regex.name, t("Invalid value")).required(t("Field is required")),
        description: Yup.string().matches(regex.description, t("Invalid value")).required(t("Field is required")),
        insurance_amount: Yup.number().required(t("Field is required")),
        deductable_amount: Yup.number().required(t("Field is required")),
    });
};

export const productInitialValue = product;
export const productValidation = validation;
export const productTypeInitialValue = productType;
export const productTypeValidation = typeValidation;
