import React, { useState } from "react";
import { Button, Modal } from "antd";
import { Field, Form, Formik } from "formik";
import { AntInput, AntNumber, AntTextArea } from "../../../hooks/createAntDFields";
import { productTypeInitialValue, productTypeValidation } from "../../../constants/product";
import REACT_APP from "../../../environment";
import { toast } from "react-toastify";
import axios from "axios";
import { useDispatch } from "react-redux";
import { endWait, startWait } from "../../../store/waiting";
import { useTranslation } from "react-i18next";

const CreateProductType = ({ getData, product }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const validate = productTypeValidation(t);

    const [isModalOpen, setIsModalOpen] = useState(false);
    const showModal = () => setIsModalOpen(true);
    const cancel = (resetForm) => {
        if (typeof resetForm === "function") resetForm();
        setIsModalOpen(false);
    };

    const submit = async (data, { setSubmitting, resetForm }) => {
        dispatch(startWait());
        delete data.product;
        data.product_id = product.id;
        await axios
            .post(`${REACT_APP.API_URL}/api/hrp/provider/v1/product/type`, data, { headers: { Authorization: sessionStorage.getItem("token") } })
            .then(() => {
                resetForm();
                toast.success(t("Created!"));
                getData();
                setIsModalOpen(false);
            })
            .catch((err) => {
                console.log(err);
                toast.error(t((err.response?.data?.errors && err.response.data.errors[0]?.message) || err.message));
            });
        dispatch(endWait());
        setSubmitting(false);
    };

    return (
        <>
            <div className="float-right mr-4">
                <button
                    onClick={showModal}
                    type="button"
                    className="text-green-700 hover:text-white border border-green-700 hover:bg-green-700 focus:ring-4 focus:outline-none focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2 text-center dark:border-green-500 dark:text-green-500 dark:hover:text-white dark:hover:bg-green-500 dark:focus:ring-green-800 transition-all"
                >
                    {t("Create type")}
                </button>
            </div>
            <Formik initialValues={productTypeInitialValue} validationSchema={validate} onSubmit={submit}>
                {({ isSubmitting, submitCount, errors, resetForm }) => (
                    <Modal title={t("Create product type")} open={isModalOpen} footer={[]} onCancel={() => cancel(resetForm)}>
                        <div className="pt-4">
                            <Form autoComplete="off">
                                <div>
                                    <label htmlFor="product">{t("Product")}</label>
                                    <Field component={AntInput} size="large" readOnly value={product.name} name="product" />
                                    <label htmlFor="name">{t("Product type name")}</label>
                                    <Field component={AntInput} submitCount={submitCount} size="large" name="name" />
                                    <div className="grid gap-2 grid-cols-2">
                                        <div>
                                            <label htmlFor="insurance_amount">{t("Insurance amount")}</label>
                                            <Field component={AntNumber} submitCount={submitCount} size="large" name="insurance_amount" />
                                        </div>
                                        <div>
                                            <label htmlFor="deductable_amount">{t("Deductable amount")}</label>
                                            <Field component={AntNumber} submitCount={submitCount} size="large" name="deductable_amount" />
                                        </div>
                                    </div>
                                    <label htmlFor="description">{t("Description")}</label>
                                    <Field
                                        component={AntTextArea}
                                        submitCount={submitCount}
                                        size="large"
                                        name="description"
                                        autoSize={{ minRows: 3, maxRows: 5 }}
                                    />
                                </div>
                                <div className="ant-modal-footer">
                                    <Button onClick={() => cancel(resetForm)}>{t("Cancel")}</Button>
                                    <Button type="primary" htmlType="submit">
                                        {t("Submit")}
                                    </Button>
                                </div>
                            </Form>
                        </div>
                    </Modal>
                )}
            </Formik>
        </>
    );
};
export default CreateProductType;
