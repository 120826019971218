const NODE_ENV = process.env.REACT_APP_ENV || "production";
// scp -r build root@159.223.217.246:/var/www/provider

const REACT_APP = {
    local: {
        API_URL: "http://192.168.100.9:9107",
    },
    development: {
        API_URL: "http://159.223.205.127:9107",
    },
    production: {
        API_URL: "https://api7.ishkuni.uz",
    },
};

export default REACT_APP[NODE_ENV];
