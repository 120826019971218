import React from "react";
import PolicyIndex from "../components/Policy/Index";

const Policy = () => {
    return (
        <div style={{ position: "relative" }}>
            <PolicyIndex />
        </div>
    );
};

export default Policy;
