import React, { useState } from "react";
import { Button, Modal } from "antd";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { Field, Form, Formik } from "formik";
import { AntInput } from "../../hooks/createAntDFields";
import { changePasswordValidation } from "../../constants/user";
import { changePasswordInitialValue } from "../../constants/user";
import { endWait, startWait } from "../../store/waiting";
import { useTranslation } from "react-i18next";
import REACT_APP from "../../environment";
import axios from "axios";

const ChangePassword = ({ getData }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const validate = changePasswordValidation(t);

    const [isModalOpen, setIsModalOpen] = useState(false);
    const showModal = () => setIsModalOpen(true);
    const cancel = (resetForm) => {
        if (typeof resetForm === "function") resetForm();
        setIsModalOpen(false);
    };

    const submit = async (data, { setSubmitting, resetForm }) => {
        dispatch(startWait());
        const body = { ...data };
        delete body.confirm_password;
        await axios
            .patch(`${REACT_APP.API_URL}/api/hrp/provider/v1/user/secret`, body, { headers: { Authorization: sessionStorage.getItem("token") } })
            .then(() => {
                resetForm();
                getData();
                setIsModalOpen(false);
                toast.success(t("Updated!"));
            })
            .catch((err) => {
                console.log(err);
                toast.error(t((err.response?.data?.errors && err.response.data.errors[0]?.message) || err.message));
            });
        setSubmitting(false);
        dispatch(endWait());
    };

    return (
        <>
            <div className="float-right">
                <button
                    onClick={showModal}
                    type="button"
                    className="text-green-700 hover:text-white border border-green-700 hover:bg-green-700 focus:ring-4 focus:outline-none focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2 text-center dark:border-green-500 dark:text-green-500 dark:hover:text-white dark:hover:bg-green-500 dark:focus:ring-green-800 transition-all"
                >
                    {t("Change password")}
                </button>
            </div>
            <Formik initialValues={changePasswordInitialValue} validationSchema={validate} onSubmit={submit}>
                {({ submitCount, resetForm }) => (
                    <Modal title={t("Change password")} open={isModalOpen} footer={[]} onCancel={() => cancel(resetForm)}>
                        <div className="pt-4">
                            <Form autoComplete="off">
                                <div>
                                    <label htmlFor="password">{t("Password")}</label>
                                    <Field component={AntInput} submitCount={submitCount} size="large" name="password" />
                                    <label htmlFor="new_password">{t("New password")}</label>
                                    <Field component={AntInput} submitCount={submitCount} size="large" name="new_password" />
                                    <label htmlFor="confirm_password">{t("Confirm password")}</label>
                                    <Field component={AntInput} submitCount={submitCount} size="large" name="confirm_password" />
                                </div>
                                <div className="ant-modal-footer">
                                    <Button onClick={() => cancel(resetForm)}>{t("Cancel")}</Button>
                                    <Button type="primary" htmlType="submit">
                                        {t("Submit")}
                                    </Button>
                                </div>
                            </Form>
                        </div>
                    </Modal>
                )}
            </Formik>
        </>
    );
};
export default ChangePassword;
