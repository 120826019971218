import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { dateTimeLocalISO } from "../../hooks/dateFormatter";
import { useSearchParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Button } from "antd";
import { Select } from "antd";
import axios from "axios";
import REACT_APP from "../../environment";
import * as XLSX from "xlsx";

const Filter = ({ rowsPerPage, handlePageChange, isNotChange, setIsNotChange, getData, page, data }) => {
    const { t } = useTranslation();
    const [searchParams, setSearchParams] = useSearchParams();

    const statuses = [
        { label: t("new"), value: 1 },
        // { label: t("deleted"), value: 2 },
        // { label: t("approved"), value: 3 },
        { label: t("active"), value: 4 },
        { label: t("expired"), value: 5 },
        { label: t("canceled"), value: 6 },
    ];
    const pages = [
        { label: 5, value: 5 },
        { label: 10, value: 10 },
        { label: 20, value: 20 },
        { label: 50, value: 50 },
    ];

    const [products, setProducts] = useState([]);
    const [productOptions, setProductOptions] = useState([]);
    const [types, setTypes] = useState([]);
    const [typeOptions, setTypeOptions] = useState([]);

    const [sProducts, setSProducts] = useState(
        searchParams
            .get("product")
            ?.split(",")
            .map((e) => parseInt(e)) || []
    );
    const [sTypes, setSTypes] = useState(
        searchParams
            .get("type")
            ?.split(",")
            .map((e) => parseInt(e)) || []
    );
    const [sStatuses, setSStatuses] = useState(
        searchParams
            .get("status")
            ?.split(",")
            .map((e) => parseInt(e)) || []
    );

    const getProducts = async () => {
        await axios
            .get(`${REACT_APP.API_URL}/api/hrp/provider/v1/product/all`, { headers: { Authorization: sessionStorage.getItem("token") } })
            .then((res) => setProducts(res.data.data.products))
            .catch((err) => {
                console.log(err);
                toast.error(t((err.response?.data?.errors && err.response.data.errors[0]?.message) || err.message));
            });
    };

    const getProductTypes = async (id) => {
        await axios
            .get(`${REACT_APP.API_URL}/api/hrp/provider/v1/product/${id}/type/all`, { headers: { Authorization: sessionStorage.getItem("token") } })
            .then((res) => setTypes(res.data.data.types))
            .catch((err) => {
                console.log(err);
                toast.error(t((err.response?.data?.errors && err.response.data.errors[0]?.message) || err.message));
            });
    };

    useEffect(() => {
        getProducts();
    }, []);

    useEffect(() => {
        setProductOptions(products.map((e) => ({ label: e.name, value: e.id })));
    }, [products]);

    const handleProductChange = (value) => {
        setIsNotChange(false);
        setSProducts(value);
        setSTypes([]);
    };

    useEffect(() => {
        if (sProducts.length === 1) getProductTypes(sProducts[0]);
        else setTypes([]);
    }, [sProducts]);

    useEffect(() => {
        setTypeOptions(types.map((e) => ({ label: e.name, value: e.id })));
    }, [types]);

    const handleTypeChange = (value) => {
        setSTypes(value);
        setIsNotChange(false);
    };

    const handleStatusNotChange = (value) => {
        setSStatuses(value);
        setIsNotChange(false);
    };

    const navigate = useNavigate();
    const changeURLSearchParams = () => {
        const parameters = [];
        if (sStatuses.length > 0) parameters.push(`status=${sStatuses}`);
        if (sProducts.length > 0) parameters.push(`product=${sProducts}`);
        if (sTypes.length > 0) parameters.push(`type=${sTypes}`);
        parameters.push("page=" + page, "rows=" + rowsPerPage);
        const params = parameters.length === 0 ? "" : `?${parameters.join("&")}`;
        // setSearchParams(params);
        navigate(`/policy${params}`);
    };

    useEffect(() => {
        changeURLSearchParams();
    }, [sStatuses, sTypes, sProducts, page, rowsPerPage]);

    const onFilter = () => {
        getData();
    };

    const downloadExcel = () => {
        const wscols = [{ wch: 6 }, { wch: 15 }, { wch: 15 }, { wch: 20 }, { wch: 20 }, { wch: 25 }];

        const result = data.map((e, i) => ({
            [t("№")]: i + 1,
            [t("First name")]: e.customer_first_name,
            [t("Last name")]: e.customer_last_name,
            [t("Company")]: e.company_name,
            [t("Product")]: e.product_name,
            [t("Product type name")]: e.product_type_name,
        }));

        const worksheet = XLSX.utils.json_to_sheet(result);
        const workbook = XLSX.utils.book_new();
        worksheet["!cols"] = wscols;

        XLSX.utils.book_append_sheet(workbook, worksheet, t("Customer"));
        XLSX.writeFile(workbook, `${dateTimeLocalISO(new Date())}_${t("result")}.xlsx`);
    };

    return (
        <div className="px-5 mb-5 flex gap-x-10 gap-y-2 flex-wrap">
            <Select
                mode="multiple"
                style={{ minWidth: "160px" }}
                placeholder={t("Select status")}
                defaultValue={sStatuses}
                onChange={handleStatusNotChange}
                options={statuses}
                size="large"
            />
            <Select
                mode="multiple"
                style={{ minWidth: "160px" }}
                placeholder={t("Products")}
                defaultValue={sProducts}
                onChange={handleProductChange}
                options={productOptions}
                size="large"
            />
            <Select
                mode="multiple"
                style={{ minWidth: "160px" }}
                placeholder={t("Types")}
                disabled={sProducts.length !== 1}
                value={sTypes}
                onChange={handleTypeChange}
                options={typeOptions}
                size="large"
            />
            <Select
                style={{ minWidth: "80px" }}
                placeholder={t("Rows per page")}
                defaultValue={[rowsPerPage]}
                onChange={handlePageChange}
                options={pages}
                size="large"
            />
            <Button size="large" type="primary" onClick={onFilter}>
                {t("Filter")}
            </Button>
            {isNotChange === true && (
                <Button size="large" type="primary" onClick={downloadExcel}>
                    {t("Download result")}
                </Button>
            )}
        </div>
    );
};

export default Filter;
