import React from "react";
import { DatePicker, Form, Input, TimePicker, Select, InputNumber } from "antd";
import TextArea from "antd/es/input/TextArea";

const FormItem = Form.Item;
const { Option } = Select;

const CreateAntField =
    (AntComponent) =>
    ({ field, form, hasFeedback, label, selectOptions, submitCount, type, ...props }) => {
        const submitted = submitCount > 0;
        const touched = form.touched[field.name];
        const hasError = form.errors[field.name];
        const submittedError = hasError && submitted;
        const touchedError = hasError && touched;
        const onInputChange = ({ target: { value } }) => form.setFieldValue(field.name, value);
        const onChange = (value) => form.setFieldValue(field.name, typeof value?.target?.value === "string" ? value.target.value : value);
        const onBlur = () => form.setFieldTouched(field.name, true);
        return (
            <div className="field-container" style={{ width: "100%" }}>
                <FormItem
                    label={label}
                    // hasFeedback={(hasFeedback && submitted) || (hasFeedback && touched) ? true : false}
                    help={submittedError || touchedError ? hasError : false}
                    validateStatus={submittedError || touchedError ? "error" : "success"}
                >
                    <AntComponent {...field} {...props} onBlur={onBlur} onChange={type ? onInputChange : onChange} style={{ width: "100%" }}>
                        {selectOptions && selectOptions.map((name) => <Option key={name}>{name}</Option>)}
                    </AntComponent>
                </FormItem>
            </div>
        );
    };

export const AntSelect = CreateAntField(Select);
export const AntDatePicker = CreateAntField(DatePicker);
export const AntInput = CreateAntField(Input);
export const AntNumber = CreateAntField(InputNumber);
export const AntTextArea = CreateAntField(TextArea);
export const AntPassword = CreateAntField(Input.Password);
export const AntTimePicker = CreateAntField(TimePicker);
