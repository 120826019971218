import React, { useEffect, useState } from "react";
import IconAction from "../../icons/action";
import { Dropdown, Space } from "antd";
import { DeleteOutlined, CarryOutOutlined, FormOutlined } from "@ant-design/icons";
import UpdateProduct from "./UpdateProduct";
import DeleteProduct from "./DeleteProduct";
import SubmitProduct from "./SubmitProduct";
import { useTranslation } from "react-i18next";

const ProductTable = ({ data, getData, selected, setSelected }) => {
    const { t } = useTranslation();
    const [isShowUpdate, setIsShowUpdate] = useState(false);
    const [isShowDelete, setIsShowDelete] = useState(false);
    const [isShowSubmit, setIsShowSubmit] = useState(false);
    const [edit, setEdit] = useState(null);

    const itemsGetter = (product) => {
        return [
            { label: <span onClick={() => onUpdate(product)}>{t("Update")}</span>, key: "update", icon: <FormOutlined />, disabled: product.status === 2 },
            { label: <span onClick={() => onDelete(product)}>{t("Delete")}</span>, key: "delete", icon: <DeleteOutlined /> },
            { label: <span onClick={() => onSubmit(product)}>{t("Submit")}</span>, key: "submit", icon: <CarryOutOutlined />, disabled: product.status === 2 },
        ];
    };

    const onUpdate = (product) => {
        setEdit(product);
        setIsShowUpdate(true);
    };

    const onDelete = (product) => {
        setEdit(product);
        setIsShowDelete(true);
    };

    const onSubmit = (product) => {
        setEdit(product);
        setIsShowSubmit(true);
    };

    useEffect(() => {
        setEdit(null);
    }, [selected]);

    return (
        <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
            <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                    <tr>
                        <th scope="col" className="px-6 py-3">
                            {t("product name")}
                        </th>
                        <th scope="col" className="px-6 py-3">
                            {t("description")}
                        </th>
                        <th scope="col" className="px-6 py-3"></th>
                    </tr>
                </thead>
                <tbody>
                    {data.map((product) => (
                        <tr
                            key={`p${product.id}`}
                            onClick={() => setSelected(product)}
                            style={bg2(product.status)}
                            className={`border-b dark:bg-gray-800 dark:border-gray-700 ${(edit === product || selected === product) && "bg-orange-50/70"}`}
                        >
                            <th scope="row" className="flex px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                {product.name}
                            </th>
                            <td className="px-6 py-4 w-[80%]">{product.description}</td>
                            <td className="px-6 py-4">
                                <Dropdown menu={{ items: itemsGetter(product) }} trigger={["click"]}>
                                    <a onClick={(e) => e.preventDefault()}>
                                        <Space>
                                            <IconAction />
                                        </Space>
                                    </a>
                                </Dropdown>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <UpdateProduct product={edit} isModalOpen={isShowUpdate} setIsModalOpen={setIsShowUpdate} getData={getData} />
            <DeleteProduct product={edit} isModalOpen={isShowDelete} setIsModalOpen={setIsShowDelete} getData={getData} />
            <SubmitProduct product={edit} isModalOpen={isShowSubmit} setIsModalOpen={setIsShowSubmit} getData={getData} />
        </div>
    );
};

export default ProductTable;

export function bg(status) {
    switch (status) {
        case 1:
            return "";
        case 2:
            return "bg-blue-200";
        case 23:
            return "";
        case 3:
            return "";

        default:
            return "";
    }
}

export function bg2(status) {
    switch (status) {
        case 1:
            return { borderLeft: "5px solid rgb(191 219 254)" };
        case 2:
            return { borderLeft: "5px solid rgb(187 247 208)" };
        case 3:
            return { borderLeft: "5px solid rgb(254 202 202)" };

        default:
            return { borderLeft: "5px solid rgb(191 219 254)" };
    }
}
