import React from "react";
import ProfileIndex from "../components/Profile/Index";

const Profile = () => {
    return (
        <div>
            <ProfileIndex />
        </div>
    );
};

export default Profile;
