import React, { useEffect, useState } from "react";
import ContactTable from "./Table";
import CreateContact from "./CreateContact";
import REACT_APP from "../../environment";
import { toast } from "react-toastify";
import axios from "axios";
import SpinnerTTS from "../../assets/spinner/Spinner";
import { Alert, Empty } from "antd";
import { useTranslation } from "react-i18next";

const Contact = () => {
    const { t } = useTranslation();
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [isError, setIsError] = useState(false);

    const getData = async () => {
        setLoading(true);
        setIsError(false);
        await axios
            .get(`${REACT_APP.API_URL}/api/hrp/provider/v1/contact`, { headers: { Authorization: sessionStorage.getItem("token") } })
            .then((res) => setData(res.data.data.contacts))
            .catch((err) => {
                setIsError(true);
                console.log(err);
                toast.error(t((err.response?.data?.errors && err.response.data.errors[0]?.message) || err.message));
            });
        setLoading(false);
    };

    useEffect(() => {
        getData();
    }, []);

    return loading ? (
        <SpinnerTTS />
    ) : isError ? (
        <Alert message={t("An error occurred")} type="error" className="max-w-5xl mx-auto my-10" />
    ) : (
        <div className="container mx-auto py-6">
            <CreateContact getData={getData} />
            <h1 className="px-5 mb-4 mt-2 text-xl font-semibold leading-none tracking-tight text-gray-900 md:text-3xl">{t("Contacts")}</h1>
            {data.length > 0 ? <ContactTable data={data} getData={getData} /> : <Empty description={t("No data")} />}
        </div>
    );
};

export default Contact;
