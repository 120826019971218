import React, { useEffect, useState } from "react";
import { Route, Routes, Navigate, useLocation } from "react-router-dom";
import GlobalSpinnerTTS from "./assets/spinner-global/Spinner";
import { ToastContainer } from "react-toastify";
import Header from "./components/Header/Header";
import Login from "./pages/Login";
import "./App.css";
import { getWait } from "./store/waiting";
import { useSelector } from "react-redux";

function App() {
    const token = sessionStorage.getItem("token");
    const [selectedKeys, setSelectedKeys] = useState("/");
    const location = useLocation();
    const spinner = useSelector(getWait);

    useEffect(() => {
        const pathName = location.pathname;
        setSelectedKeys(pathName);
    }, [location.pathname]);

    return (
        // to-do: translate API response
        <div className="app">
            {spinner && <GlobalSpinnerTTS />}
            <ToastContainer />
            {token ? (
                <Header />
            ) : (
                <Routes>
                    <Route path="*" element={<Navigate to="/login" />} />
                    <Route path="/login" element={<Login />} />
                </Routes>
            )}
        </div>
    );
}

export default App;
