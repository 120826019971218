import React, { useEffect, useState } from "react";
import IconAction from "../../../icons/action";
import { Dropdown, Space } from "antd";
import { DeleteOutlined, CarryOutOutlined, FormOutlined } from "@ant-design/icons";
import UpdateProductType from "./UpdateType";
import DeleteProductType from "./DeleteType";
import SubmitProductType from "./SubmitType";
import { bg2 } from "../Table";
import { useTranslation } from "react-i18next";

const ProductTypeTable = ({ data, getData }) => {
    const { t } = useTranslation();
    const [isShowUpdate, setIsShowUpdate] = useState(false);
    const [isShowDelete, setIsShowDelete] = useState(false);
    const [isShowSubmit, setIsShowSubmit] = useState(false);
    const [edit, setEdit] = useState(null);

    const itemsGetter = (product) => {
        return [
            { label: <span onClick={() => onUpdate(product)}>{t("Update")}</span>, key: "update", icon: <FormOutlined />, disabled: product.status === 2 },
            { label: <span onClick={() => onDelete(product)}>{t("Delete")}</span>, key: "delete", icon: <DeleteOutlined /> },
            { label: <span onClick={() => onSubmit(product)}>{t("Submit")}</span>, key: "submit", icon: <CarryOutOutlined />, disabled: product.status === 2 },
        ];
    };

    const onUpdate = (product) => {
        setEdit(product);
        setIsShowUpdate(true);
    };

    const onDelete = (product) => {
        setEdit(product);
        setIsShowDelete(true);
    };

    const onSubmit = (product) => {
        setEdit(product);
        setIsShowSubmit(true);
    };

    return (
        <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
            <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                <tbody>
                    {data.map((productType) => (
                        <tr key={productType.id} className="border-b dark:bg-gray-800 dark:border-gray-700" style={bg2(productType.status)}>
                            <th scope="row" className="flex px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                {productType.name}
                            </th>
                            <td className="px-6 py-4 w-[80%]">
                                {productType.description}
                                <br />
                                <span className="font-semibold">{t("insurance amount")}: </span>
                                {productType.insurance_amount}
                                <br />
                                <span className="font-semibold">{t("deductable amount")}: </span>
                                {productType.deductable_amount}
                            </td>
                            <td className="px-6 py-4">
                                <Dropdown menu={{ items: itemsGetter(productType) }} trigger={["click"]}>
                                    <a onClick={(e) => e.preventDefault()}>
                                        <Space>
                                            <IconAction />
                                        </Space>
                                    </a>
                                </Dropdown>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <UpdateProductType productType={edit} isModalOpen={isShowUpdate} setIsModalOpen={setIsShowUpdate} getData={getData} />
            <DeleteProductType productType={edit} isModalOpen={isShowDelete} setIsModalOpen={setIsShowDelete} getData={getData} />
            <SubmitProductType productType={edit} isModalOpen={isShowSubmit} setIsModalOpen={setIsShowSubmit} getData={getData} />
        </div>
    );
};

export default ProductTypeTable;
