import React, { useEffect, useState } from "react";
import ProductTable from "./Table";
import CreateProduct from "./CreateProduct";
import REACT_APP from "../../environment";
import { toast } from "react-toastify";
import axios from "axios";
import SpinnerTTS from "../../assets/spinner/Spinner";
import ProductTypeTable from "./Type/TypeTable";
import CreateProductType from "./Type/CreateType";
import { useTranslation } from "react-i18next";
import { Empty } from "antd";
import { Alert } from "antd";

const Product = () => {
    const { t } = useTranslation();
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [selected, setSelected] = useState(null);
    const [isError, setIsError] = useState(false);
    const [types, setTypes] = useState({ types: [] });

    const getData = async () => {
        setLoading(true);
        setIsError(false);
        await axios
            .get(`${REACT_APP.API_URL}/api/hrp/provider/v1/product`, { headers: { Authorization: sessionStorage.getItem("token") } })
            .then((res) => setData(res.data.data.products))
            .catch((err) => {
                setIsError(true);
                console.log(err);
                toast.error(t((err.response?.data?.errors && err.response.data.errors[0]?.message) || err.message));
            });
        setLoading(false);
    };

    const getTypes = async () => {
        setIsError(false);
        await axios
            .get(`${REACT_APP.API_URL}/api/hrp/provider/v1/product/${selected.id}/type`, { headers: { Authorization: sessionStorage.getItem("token") } })
            .then((res) => setTypes({ types: res.data.data.types, selected }))
            .catch((err) => {
                setIsError(true);
                console.log(err);
                toast.error(t((err.response?.data?.errors && err.response.data.errors[0]?.message) || err.message));
            });
    };

    useEffect(() => {
        getData();
    }, []);

    useEffect(() => {
        setTypes([]);
        if (selected) getTypes();
    }, [selected]);

    return loading ? (
        <SpinnerTTS />
    ) : isError ? (
        <Alert message={t("An error occurred")} type="error" className="max-w-5xl mx-auto my-10" />
    ) : (
        <div className="container mx-auto py-6">
            <CreateProduct getData={getData} />
            {selected && <CreateProductType getData={getTypes} product={selected} />}
            <h1 className="px-5 mb-4 mt-2 text-xl font-semibold leading-none tracking-tight text-gray-900 md:text-3xl">{t("Products")}</h1>
            {data.length > 0 ? (
                <ProductTable data={data} getData={getData} selected={selected} setSelected={setSelected} />
            ) : (
                <Empty description={t("No data")} />
            )}
            {types.selected === selected &&
                (types.types.length > 0 ? (
                    <React.Fragment>
                        <h1 className="px-5 mt-8 mb-2 text-lg font-semibold leading-none tracking-tight text-gray-900 md:text-2xl">
                            {t("Product types")}: {selected.name}
                        </h1>
                        <ProductTypeTable data={types.types} getData={getTypes} />
                    </React.Fragment>
                ) : (
                    <Alert message={t("There are no product types yet")} type="warning" className="my-10" />
                ))}
        </div>
    );
};

export default Product;
