import React, { useEffect } from "react";
import { Button, Modal } from "antd";
import { Field, Form, Formik } from "formik";
import { AntInput, AntTextArea } from "../../hooks/createAntDFields";
import axios from "axios";
import { toast } from "react-toastify";
import REACT_APP from "../../environment";
import { useDispatch } from "react-redux";
import { endWait, startWait } from "../../store/waiting";
import { productValidation, productInitialValue } from "../../constants/product";
import { useTranslation } from "react-i18next";

const UpdateProduct = ({ product, isModalOpen, setIsModalOpen, getData }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const validate = productValidation(t);

    const cancel = (resetForm) => {
        if (typeof resetForm === "function") resetForm();
        setIsModalOpen(false);
    };

    const submit = async (data, { setSubmitting, resetForm }) => {
        dispatch(startWait());
        delete data.status;
        await axios
            .put(`${REACT_APP.API_URL}/api/hrp/provider/v1/product`, data, { headers: { Authorization: sessionStorage.getItem("token") } })
            .then(() => {
                resetForm();
                toast.success(t("Updated!"));
                getData();
                setIsModalOpen(false);
            })
            .catch((err) => {
                console.log(err);
                toast.error(t((err.response?.data?.errors && err.response.data.errors[0]?.message) || err.message));
            });
        setSubmitting(false);
        dispatch(endWait());
    };

    return (
        <>
            <Formik initialValues={product || productInitialValue} enableReinitialize validationSchema={validate} onSubmit={submit}>
                {({ isSubmitting, submitCount, errors, resetForm }) => (
                    <Modal title={t("Update product")} open={isModalOpen} footer={[]} onCancel={() => cancel(resetForm)}>
                        <div className="pt-4">
                            <Form autoComplete="off">
                                <div>
                                    <label htmlFor="name">{t("Product name")}</label>
                                    <Field component={AntInput} submitCount={submitCount} size="large" name="name" />
                                    <label htmlFor="description">{t("Description")}</label>
                                    <Field
                                        component={AntTextArea}
                                        submitCount={submitCount}
                                        size="large"
                                        name="description"
                                        autoSize={{ minRows: 3, maxRows: 5 }}
                                    />
                                </div>
                                <div className="ant-modal-footer">
                                    <Button onClick={() => cancel(resetForm)}>{t("Cancel")}</Button>
                                    <Button type="primary" htmlType="submit">
                                        {t("Submit")}
                                    </Button>
                                </div>
                            </Form>
                        </div>
                    </Modal>
                )}
            </Formik>
        </>
    );
};
export default UpdateProduct;
