import regex from "tts-hrp-val-lib";
import * as Yup from "yup";

const approve = { id: undefined, policy_number: undefined };

const validation = (t) => {
    return Yup.object({
        id: Yup.number().optional(),
        policy_number: Yup.string().required(t("Field is required")),
    });
};

export const policyApproveInitialValue = approve;
export const policyApproveValidation = validation;
