import React from "react";
import ProductIndex from "../components/Product/Index";

const Product = () => {
    return (
        <div>
            <ProductIndex />
        </div>
    );
};

export default Product;
