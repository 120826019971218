import React, { useState } from "react";
import { Button, Modal } from "antd";
import { Field, Form, Formik } from "formik";
import { AntInput } from "../../hooks/createAntDFields";
import { userInitialValue, userValidation } from "../../constants/user";
import { endWait, startWait } from "../../store/waiting";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import REACT_APP from "../../environment";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { usernameColor, usernamePrefix } from "../Profile/Edit";
import regex from "tts-hrp-val-lib";

const CreateUser = ({ getData }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const validate = userValidation(t);

    const [isModalOpen, setIsModalOpen] = useState(false);
    const showModal = () => setIsModalOpen(true);
    const cancel = (resetForm) => {
        if (typeof resetForm === "function") resetForm();
        setIsModalOpen(false);
    };

    const submit = async (data, { setSubmitting, resetForm }) => {
        dispatch(startWait());
        const body = { ...data };
        delete body.usernameStatus;
        delete body.id;
        if (!body.last_name) delete body.last_name;
        await axios
            .post(`${REACT_APP.API_URL}/api/hrp/provider/v1/user`, body, { headers: { Authorization: sessionStorage.getItem("token") } })
            .then(() => {
                resetForm();
                toast.success(t("Created!"));
                getData();
                setIsModalOpen(false);
            })
            .catch((err) => {
                console.log(err);
                toast.error(t((err.response?.data?.errors && err.response.data.errors[0]?.message) || err.message));
            });
        setSubmitting(false);
        dispatch(endWait());
    };

    const checkUsername = (username, values, setValues) => {
        values.usernameStatus = null;
        values.username = username;
        setValues(values);

        setTimeout(() => {
            if (document.getElementById("username").value === username && regex.username.test(username)) {
                axios
                    .patch(
                        `${REACT_APP.API_URL}/api/hrp/provider/v1/user/check/username`,
                        { username },
                        { headers: { Authorization: sessionStorage.getItem("token") } }
                    )
                    .then((res) => {
                        if (document.getElementById("username").value === username) {
                            if (res.data.data.result === true) values.usernameStatus = false;
                            else values.usernameStatus = true;
                            setValues(values);
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                        toast.error(t((err.response?.data?.errors && err.response.data.errors[0]?.message) || err.message));
                    });
            }
        }, 400);
    };

    return (
        <>
            <div className="float-right">
                <button
                    onClick={showModal}
                    type="button"
                    className="text-green-700 hover:text-white border border-green-700 hover:bg-green-700 focus:ring-4 focus:outline-none focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2 text-center dark:border-green-500 dark:text-green-500 dark:hover:text-white dark:hover:bg-green-500 dark:focus:ring-green-800 transition-all"
                >
                    {t("Create user")}
                </button>
            </div>
            <Formik initialValues={userInitialValue} validationSchema={validate} onSubmit={submit}>
                {({ submitCount, values, resetForm, setValues }) => (
                    <Modal title={t("Create user")} open={isModalOpen} footer={[]} onCancel={() => cancel(resetForm)}>
                        <div className="pt-4">
                            <Form autoComplete="off">
                                <div>
                                    <label htmlFor="first_name">{t("First name")}</label>
                                    <Field component={AntInput} submitCount={submitCount} size="large" name="first_name" />
                                    <label htmlFor="last_name">{t("Last name")}</label>
                                    <Field component={AntInput} submitCount={submitCount} size="large" name="last_name" />
                                    <label htmlFor="email">{t("Email")}</label>
                                    <Field component={AntInput} submitCount={submitCount} size="large" name="email" />
                                    <label htmlFor="username">{t("Username")}</label>
                                    <Field
                                        id="username"
                                        size="large"
                                        name="username"
                                        component={AntInput}
                                        submitCount={1}
                                        status={usernameColor(values.usernameStatus)}
                                        prefix={usernamePrefix(values.usernameStatus)}
                                        onInput={(e) => checkUsername(e.target.value, values, setValues)}
                                    />
                                </div>
                                <div className="ant-modal-footer">
                                    <Button onClick={() => cancel(resetForm)}>{t("Cancel")}</Button>
                                    <Button type="primary" htmlType="submit">
                                        {t("Submit")}
                                    </Button>
                                </div>
                            </Form>
                        </div>
                    </Modal>
                )}
            </Formik>
        </>
    );
};
export default CreateUser;
