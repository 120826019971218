import React, { useState } from "react";
import IconAction from "../../icons/action";
import { Dropdown, Space } from "antd";
import { DeleteOutlined, FormOutlined } from "@ant-design/icons";
import UpdateContact from "./UpdateContact";
import DeleteContact from "./DeleteContact";
import { useTranslation } from "react-i18next";

const ContactTable = ({ data, getData }) => {
    const { t } = useTranslation();
    const [isShowUpdate, setIsShowUpdate] = useState(false);
    const [isShowDelete, setIsShowDelete] = useState(false);
    const [edit, setEdit] = useState(null);

    const itemsGetter = (contact) => {
        return [
            { label: <span onClick={() => onUpdate(contact)}>{t("Update")}</span>, key: "update", icon: <FormOutlined /> },
            { label: <span onClick={() => onDelete(contact)}>{t("Delete")}</span>, key: "delete", icon: <DeleteOutlined /> },
        ];
    };

    const onUpdate = (contact) => {
        setEdit(contact);
        setIsShowUpdate(true);
    };

    const onDelete = (contact) => {
        setEdit(contact);
        setIsShowDelete(true);
    };

    return (
        <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
            <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                    <tr>
                        <th scope="col" className="px-6 py-3">
                            {t("name")}
                        </th>
                        <th scope="col" className="px-6 py-3">
                            {t("phone")}
                        </th>
                        <th scope="col" className="px-6 py-3">
                            {t("email")}
                        </th>
                        <th scope="col" className="px-6 py-3">
                            {t("position")}
                        </th>
                        <th scope="col" className="px-6 py-3"></th>
                    </tr>
                </thead>
                <tbody>
                    {data.map((contact) => (
                        <tr key={contact.id} className="border-b dark:bg-gray-800 dark:border-gray-700">
                            <td className="px-6 py-4">{contact.name}</td>
                            <td className="px-6 py-4">{contact.phone}</td>
                            <td className="px-6 py-4">{contact.email}</td>
                            <td className="px-6 py-4">{contact.position}</td>
                            <td className="px-6 py-4">
                                <Dropdown menu={{ items: itemsGetter(contact) }} trigger={["click"]}>
                                    <a onClick={(e) => e.preventDefault()}>
                                        <Space>
                                            <IconAction />
                                        </Space>
                                    </a>
                                </Dropdown>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <UpdateContact contact={edit} isModalOpen={isShowUpdate} setIsModalOpen={setIsShowUpdate} getData={getData} />
            <DeleteContact contact={edit} isModalOpen={isShowDelete} setIsModalOpen={setIsShowDelete} getData={getData} />
        </div>
    );
};

export default ContactTable;
