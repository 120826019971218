import React from "react";
import UserIndex from "../components/User/Index";

const User = () => {
    return (
        <div>
            <UserIndex />
        </div>
    );
};

export default User;
