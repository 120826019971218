import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { Alert, Empty, Pagination } from "antd";
import { useLocation, useSearchParams } from "react-router-dom";
import SpinnerTTS from "../../assets/spinner/Spinner";
import REACT_APP from "../../environment";
import CustomerTable from "./Table";
import Filter from "./Filter";
import axios from "axios";
import CheckNewRequest from "./Check";

const Policy = () => {
    const { t } = useTranslation();
    const location = useLocation();
    const [searchParams] = useSearchParams();
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [isError, setIsError] = useState(false);

    const [isNotChange, setIsNotChange] = useState(true);
    const [page, setPage] = useState(searchParams.get("page") || 1);
    const [rowsPerPage, setRowsPerPage] = useState(searchParams.get("rows") || 20);
    const changePage = (value, length) => {
        setPage(value);
    };
    const handlePageChange = (length) => {
        setPage(1);
        setRowsPerPage(length);
    };

    const getData = async () => {
        setLoading(true);
        setIsError(false);
        if (!isNotChange) setPage(1);

        await axios
            .get(`${REACT_APP.API_URL}/api/hrp/provider/v1/customer${location.search}`, { headers: { Authorization: sessionStorage.getItem("token") } })
            .then((res) => {
                setIsNotChange(true);
                setData(res.data.data.benefits);
            })
            .catch((err) => {
                setIsError(true);
                console.log(err);
                toast.error(t((err.response?.data?.errors && err.response.data.errors[0]?.message) || err.message));
            });
        setLoading(false);
    };

    useEffect(() => {
        getData();
    }, []);

    return (
        <div className="container mx-auto py-6">
            <h1 className="px-5 mb-4 mt-2 text-xl font-semibold leading-none tracking-tight text-gray-900 md:text-3xl">{t("Customers")}</h1>
            <CheckNewRequest getData={getData} data={data} />
            <Filter
                getData={getData}
                isNotChange={isNotChange && data.length > 0}
                setIsNotChange={setIsNotChange}
                rowsPerPage={rowsPerPage}
                handlePageChange={handlePageChange}
                page={page}
                data={data}
            />
            {loading ? (
                <div className="absolute top-0 left-0 w-full h-[calc(100vh_-_112px)]">
                    <SpinnerTTS />
                </div>
            ) : isError ? (
                <Alert message={t("An error occurred")} type="error" className="max-w-5xl mx-auto my-10" />
            ) : data.length > 0 ? (
                <React.Fragment>
                    <CustomerTable data={data.slice(rowsPerPage * (page - 1), rowsPerPage * page)} getData={getData} />
                    {data.slice(rowsPerPage * (page - 1), rowsPerPage * page).length !== data.length && (
                        <Pagination className="my-2" current={page} pageSize={rowsPerPage} showSizeChanger={false} onChange={changePage} total={data.length} />
                    )}
                </React.Fragment>
            ) : (
                <Empty description={t("No data")} />
            )}
        </div>
    );
};

export default Policy;
