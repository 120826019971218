export const dateTimeLocalISO = (date) => {
    if (!date) return "";
    const offset = date.getTimezoneOffset();
    date = new Date(date.getTime() - offset * 60 * 1000);
    return (
        date.toISOString().slice(0, 10) +
        "_" +
        date.toISOString().slice(11, 13) +
        "-" +
        date.toISOString().slice(14, 16) +
        "-" +
        date.toISOString().slice(17, 19)
    );
};
