import React from "react";
import style from "./spinner.module.css";

export default function GlobalSpinnerTTS() {
    return (
        <div className={style["tts-spinner"]}>
            <div className={style["ts-window"]}>
                <div className={style["ts-border"]}>
                    <div className={style["ts-container"]}>
                        <span>TTS</span>
                        <div className={style["lds-ellipsis"]}>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
