import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import "./index.css";
import App from "./App";
import axios from "axios";
import { logout } from "./services/logout";
import { Provider } from "react-redux";
import { store } from "./store";
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n";

axios.interceptors.response.use(
    (response) => {
        const newToken = response.data.data?.refreshToken;
        if (newToken) {
            delete response.data.data?.refreshToken;
            sessionStorage.setItem("token", newToken);
        }
        return response;
    },
    (error) => {
        if (error?.response?.status === 499) return logout();
        return Promise.reject(error);
    }
);

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
    <React.StrictMode>
        <I18nextProvider i18n={i18n}>
            <Provider store={store}>
                <BrowserRouter>
                    <App />
                </BrowserRouter>
            </Provider>
        </I18nextProvider>
    </React.StrictMode>
);
