import React, { useEffect, useState } from "react";
import REACT_APP from "../../environment";
import { toast } from "react-toastify";
import axios from "axios";
import SpinnerTTS from "../../assets/spinner/Spinner";
import { Alert } from "antd";
import { useTranslation } from "react-i18next";
import image from "../../assets/avatar-2.png";
import EditMyInfo from "./Edit";
import ChangePassword from "./ChangePassword";

const MyProfile = () => {
    const { t } = useTranslation();
    const [data, setData] = useState({});
    const [loading, setLoading] = useState(true);
    const [isError, setIsError] = useState(false);
    const isSupervisor = sessionStorage.getItem("isSupervisor");
    const provider = sessionStorage.getItem("provider");

    const getData = async () => {
        setLoading(true);
        setIsError(false);
        await axios
            .get(`${REACT_APP.API_URL}/api/hrp/provider/v1/user/my-info`, { headers: { Authorization: sessionStorage.getItem("token") } })
            .then((res) => setData(res.data.data.user))
            .catch((err) => {
                setIsError(true);
                console.log(err);
                toast.error(t((err.response?.data?.errors && err.response.data.errors[0]?.message) || err.message));
            });
        setLoading(false);
    };

    useEffect(() => {
        getData();
    }, []);

    return loading ? (
        <SpinnerTTS />
    ) : isError ? (
        <Alert message={t("An error occurred")} type="error" className="max-w-5xl mx-auto my-10" />
    ) : (
        <div className="container mx-auto py-6">
            <h1 className="px-5 mb-4 mt-2 text-xl font-semibold leading-none tracking-tight text-gray-900 md:text-3xl">{t("My info")}</h1>
            <div className="md:grid grid-cols-[auto_1fr] gap-10 max-w-5xl">
                <div className="app-box-shadow p-10 text-center flex flex-col items-center">
                    <img src={image} className="w-[160px]" alt="avatar" />
                    <h1 className="mt-2 text-lg font-semibold leading-none tracking-tight text-gray-900 md:text-2xl">
                        {data.first_name} {data.last_name}
                    </h1>
                    <div>
                        {provider}
                        {isSupervisor === "true" && t(", supervisor")}
                    </div>
                </div>
                <div className="app-box-shadow px-10 pt-10 pb-5 gap-x-10 grid grid-cols-[auto_1fr]">
                    <div className="text-lg font-semibold text-gray-700">{t("First name")}</div>
                    <div className="text-lg capitalize">{data.first_name}</div>
                    <hr className="col-[1_/_3]" />
                    <div className="text-lg font-semibold text-gray-700">{t("Last name")}</div>
                    <div className="text-lg capitalize">{data.last_name}</div>
                    <hr className="col-[1_/_3]" />
                    <div className="text-lg font-semibold text-gray-700">{t("Email")}</div>
                    <div className="text-lg lowercase">{data.email}</div>
                    <hr className="col-[1_/_3]" />
                    <div className="text-lg font-semibold text-gray-700">{t("Username")}</div>
                    <div className="text-lg">{data.username}</div>
                    <hr className="col-[1_/_3]" />
                    <div className="col-[1_/_3]">
                        <div className="flex gap-x-5 mt-2">
                            <EditMyInfo data={data} getData={getData} />
                            <ChangePassword getData={getData} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MyProfile;
