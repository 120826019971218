import React from "react";
import axios from "axios";
import REACT_APP from "../../environment";
import { Button, Modal } from "antd";
import { Field, Form, Formik } from "formik";
import { AntInput } from "../../hooks/createAntDFields";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { endWait, startWait } from "../../store/waiting";
import { contactValidation, contactInitialValue } from "../../constants/contact";
import { useTranslation } from "react-i18next";

const UpdateContact = ({ contact, isModalOpen, setIsModalOpen, getData }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const validate = contactValidation(t);

    const cancel = (resetForm) => {
        if (typeof resetForm === "function") resetForm();
        setIsModalOpen(false);
    };

    const submit = async (data, { setSubmitting, resetForm }) => {
        dispatch(startWait());
        await axios
            .put(`${REACT_APP.API_URL}/api/hrp/provider/v1/contact`, data, { headers: { Authorization: sessionStorage.getItem("token") } })
            .then(() => {
                resetForm();
                toast.success(t("Updated!"));
                getData();
                setIsModalOpen(false);
            })
            .catch((err) => {
                console.log(err);
                toast.error(t((err.response?.data?.errors && err.response.data.errors[0]?.message) || err.message));
            });
        setSubmitting(false);
        dispatch(endWait());
    };

    return (
        <>
            <Formik initialValues={contact || contactInitialValue} enableReinitialize validationSchema={validate} onSubmit={submit}>
                {({ isSubmitting, submitCount, errors, resetForm }) => (
                    <Modal title={t("Update contact")} open={isModalOpen} footer={[]} onCancel={() => cancel(resetForm)}>
                        <div className="pt-4">
                            <Form autoComplete="off">
                                <div>
                                    <label htmlFor="first_name">{t("Name")}</label>
                                    <Field component={AntInput} submitCount={submitCount} size="large" name="name" />
                                    <label htmlFor="contactname">{t("Phone")}</label>
                                    <Field component={AntInput} submitCount={submitCount} size="large" name="phone" />
                                    <label htmlFor="email">{t("Email")}</label>
                                    <Field component={AntInput} submitCount={submitCount} size="large" name="email" />
                                    <label htmlFor="contactname">{t("Position")}</label>
                                    <Field component={AntInput} submitCount={submitCount} size="large" name="position" />
                                </div>
                                <div className="ant-modal-footer">
                                    <Button onClick={() => cancel(resetForm)}>{t("Cancel")}</Button>
                                    <Button type="primary" htmlType="submit">
                                        {t("Submit")}
                                    </Button>
                                </div>
                            </Form>
                        </div>
                    </Modal>
                )}
            </Formik>
        </>
    );
};
export default UpdateContact;
